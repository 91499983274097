import {call, put, takeLatest} from 'redux-saga/effects';
import {authService} from './ebay.service';
import {httpRequestsOnLoadActions} from '../http_requests_on_load';
import {
    ADD_EBAY_PRODUCT,
    ADD_EBAY_TYPE,
    CREATE_EBAY,
    DELETE_EBAY_PRODUCT,
    DELETE_EBAY_TYPE, DISCONNECT_EBAY_SETTINGS,
    EDIT_EBAY_PRODUCT,
    EDIT_EBAY_TYPE,
    GET_EBAY,
    GET_EBAY_ORDERS,
    GET_EBAY_ORDERS_SUCCESS,
    GET_EBAY_SETUP,
    GET_EBAY_SETUP_SUCCESS,
    GET_EBAY_SUCCESS, GET_EBAY_TRANSVERS, GET_EBAY_TRANSVERS_SUCCESS,
    GET_EBAY_TYPE,
    GET_EBAY_TYPE_SUCCESS, GET_ORDER_PRODUCT_MAP, GET_ORDER_PRODUCT_MAP_SUCCESS,
    PATCH_EBAY_SETUP,
    POST_EBAY_SETUP, SYNC_RESYNC,
} from './ebay.types';
import {httpRequestsOnSuccessActions} from '../http_requests_on_success';
import {httpRequestsOnErrorsActions} from '../http_requests_on_errors';

/** Create, Edit Ebay Product */

function* createEbay({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.createEbayService, payload.body);
        yield put({
            type: GET_EBAY_SUCCESS,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    }
}

function* addEbayProduct({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.createEbayService, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* editEbayProduct({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.editEbayMap, payload.body, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteEbayProduct({payload, type}) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.deleteEbayMap, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getEbay({type, payload}) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getEbayService, payload?.params);
        yield put({
            type: GET_EBAY_SUCCESS,
            payload: res.data,
        });
        if (payload?.searchType) {
            yield put(httpRequestsOnLoadActions.removeLoading(payload?.searchType));
        }
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** End */

/**  Create, Edite, Delete and Get Ebay Type */

function* getEbayType({type, payload}) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getEbayType, payload.params);
        yield put({
            type: GET_EBAY_TYPE_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* addEbayType({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(
            authService.addAmazoonType,
            payload.body,
        );
        yield put({
            type: GET_EBAY_TYPE,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteEbayType({payload, type}) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.deleteEbayType, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editEbayType({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.editEbayType, payload.body, payload.id);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/** Ebay Setup Endpoinds */

function* addEbaySeyup({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type))
    try {
        const res = yield call(
            authService.creatEbaySetup,
            payload.body,
        );
        yield put({
            type: GET_EBAY_SETUP_SUCCESS,
            payload: res.data
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type))
    }
}

function* changeEbaySetup({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type))
    try {
        const res = yield call(
            authService.editEbaySetup,
            payload.body,
        );

        yield put({
            type: GET_EBAY_SETUP_SUCCESS,
            payload: res.data
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type))
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getEbaySetup({type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getEbaySetup);
        yield put({
            type: GET_EBAY_SETUP_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* disconnectEbaySettingsSetup({payload, type}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type))
    try {
        const res =  yield call(authService.disconnectEbaySettingsSetupService);

        yield put({
            type: GET_EBAY_SETUP_SUCCESS,
            payload: res.data,
        });

        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type))
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/** Ebay Orders */
function* getEbayOrders({type, payload}) {
    if (payload?.load !== 'load') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getEbayOrdersService, payload?.params);
        yield put({
            type: GET_EBAY_ORDERS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getEbayTransvers({type, payload}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getEbayTransversService, payload?.params);
        yield put({
            type: GET_EBAY_TRANSVERS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* syncResync({type, payload}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.syncResyncService, payload?.params, payload?.orderId);
        yield put({
            type: GET_EBAY_ORDERS,
            payload: {params: payload?.requestParams, load: 'noLoad'},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* orderProductMap({type, payload}) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.orderProductMapService, payload?.id);
        yield put({
            type: GET_ORDER_PRODUCT_MAP_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (error) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchEbay = function* watchEbaySaga() {
    /** Ebay Product */
    yield takeLatest(CREATE_EBAY, createEbay);
    yield takeLatest(ADD_EBAY_PRODUCT, addEbayProduct);
    yield takeLatest(EDIT_EBAY_PRODUCT, editEbayProduct);
    yield takeLatest(DELETE_EBAY_PRODUCT, deleteEbayProduct);
    yield takeLatest(GET_EBAY, getEbay);

    /** Ebay Type  */
    yield takeLatest(GET_EBAY_TYPE, getEbayType);
    yield takeLatest(ADD_EBAY_TYPE, addEbayType);
    yield takeLatest(EDIT_EBAY_TYPE, editEbayType);
    yield takeLatest(DELETE_EBAY_TYPE, deleteEbayType);

    /** Ebay Setup */
    yield takeLatest(GET_EBAY_SETUP, getEbaySetup);
    yield takeLatest(POST_EBAY_SETUP, addEbaySeyup);
    yield takeLatest(PATCH_EBAY_SETUP, changeEbaySetup);
    yield takeLatest(DISCONNECT_EBAY_SETTINGS, disconnectEbaySettingsSetup);

    /** Ebay Orders */
    yield takeLatest(GET_EBAY_ORDERS, getEbayOrders);
    yield takeLatest(GET_EBAY_TRANSVERS, getEbayTransvers);
    yield takeLatest(SYNC_RESYNC, syncResync);
    yield takeLatest(GET_ORDER_PRODUCT_MAP, orderProductMap);
};
