import {
    addEbay,
    addEbayProduct,
    deleteEbayProduct,
    editEbayProduct,
    getEbay,
    getEbayType,
    addEbayType,
    editEbayType,
    deleteEbayType,
    getEbaySetup,
    createEbaySetup,
    changeEbaySetup,
    getEbayOrders,
    getEbayTransvers,
    syncResync,
    orderProductMap,
    disconnectEbaySettings
} from './ebay.action';

export {ebayReducer} from './ebay.reducer';
export {watchEbay} from './ebay.saga';

export const ebayActions = {
    /** Create, Edit Amazon Product */
    addEbay,
    addEbayProduct,
    editEbayProduct,
    /** End */

    /** Get Ebay */
    getEbay,
    /** End */

    /** Delete Ebay Product */
    deleteEbayProduct,
    /** End */

    /**  Ebay Type */
    getEbayType,
    addEbayType,
    editEbayType,
    deleteEbayType,
    /** End */

    /** Ebay Setup */
    getEbaySetup,
    createEbaySetup,
    changeEbaySetup,
    disconnectEbaySettings,
    /** End */

    /** Ebay Orders */
    getEbayOrders,
    getEbayTransvers,
    syncResync,
    orderProductMap,
    /** End */

};
