import {
  ADD_EBAY_PRODUCT,
  ADD_EBAY_TYPE,
  CREATE_EBAY,
  DELETE_EBAY_PRODUCT,
  DELETE_EBAY_TYPE, DISCONNECT_EBAY_SETTINGS,
  EDIT_EBAY_PRODUCT,
  EDIT_EBAY_TYPE,
  GET_EBAY, GET_EBAY_ORDERS,
  GET_EBAY_SETUP, GET_EBAY_TRANSVERS, GET_EBAY_TYPE, GET_ORDER_PRODUCT_MAP,
  PATCH_EBAY_SETUP, POST_EBAY_SETUP,
  SYNC_RESYNC,
} from './ebay.types';

/** Create, Edit Ebay Product */

export const addEbay = (body) => {
  return {
    type: CREATE_EBAY,
    payload: { body }
  }
}

export const addEbayProduct = (body) => {
  return {
    type: ADD_EBAY_PRODUCT,
    payload: { body }
  }
}

export const editEbayProduct = (body, id,) => {
  return {
    type: EDIT_EBAY_PRODUCT,
    payload: { body, id }
  }
}

export const getEbay = (  params, load, searchType ) => {
  return {
    type: GET_EBAY,
    payload: { params, load, searchType } ,
  }
}

export const deleteEbayProduct = (id) => {
  return {
    type: DELETE_EBAY_PRODUCT,
    payload: { id }
  }
}

/** End */

/** Ebay Type  */
export const getEbayType = ( params, load ) => {
  return {
    type: GET_EBAY_TYPE,
    payload: { params, load }
  }
}

export const addEbayType = (body) => {
  return {
    type: ADD_EBAY_TYPE,
    payload: { body }
  }
}

export const editEbayType = (body, id) => {
  return {
    type: EDIT_EBAY_TYPE,
    payload: { body, id }
  }
}

export const deleteEbayType = (id) => {
  return {
    type: DELETE_EBAY_TYPE,
    payload: { id }
  }
}

/** End */

/** Ebay Setup  */

export const getEbaySetup = () => {
  return {
    type: GET_EBAY_SETUP,
  }
}

export const changeEbaySetup = (body) => {
  return {
    type: PATCH_EBAY_SETUP,
    payload: { body }
  }
}

export const createEbaySetup = (body) => {
  return {
    type: POST_EBAY_SETUP,
    payload: { body }
  }
}

/** End */

/** EBAY Orders  */

export const getEbayOrders = (params) => {
  return {
    type: GET_EBAY_ORDERS,
    payload: { params }
  }
}

export const getEbayTransvers = (params) => {
  return {
    type: GET_EBAY_TRANSVERS,
    payload: { params }
  }
}

export const syncResync = (params, orderId, requestParams) => {
  return {
    type: SYNC_RESYNC,
    payload: { params, orderId, requestParams }
  }
}
export const orderProductMap = (id) => {
  return {
    type: GET_ORDER_PRODUCT_MAP,
    payload: { id }
  }
}

/** End */

export const disconnectEbaySettings = () => {
  return {
    type: DISCONNECT_EBAY_SETTINGS,
  };
};