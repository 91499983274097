import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch} from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {CloseButton, SettingsButton} from 'components';
import {Authentication} from './authentication';
import {FindError, FindSuccess} from 'hooks';
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from 'store';

export const LeftDrawer = ({statusType, setUp, actionType, AnotherSettings}) => {
    /**
     * LeftDrawer
     */

        // Hooks.
    const dispatch = useDispatch();
    const backError = FindError(statusType);
    const success = FindSuccess(actionType);
    const [state, setState] = useState({right: false});

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(statusType));
        }
    };

    const list = () => (
        <div className="list-wrapper">
            <div className="list-wrapper-head ">
                <p className="list-title ">Settings</p>
                <CloseButton handleClose={toggleDrawer('right', false)}/>
            </div>

            <div className="list-wrapper-body">
                {AnotherSettings ?
                    <AnotherSettings
                        handleClose={toggleDrawer('right', false)}
                        handleOpen={toggleDrawer('right', true)}
                        setUp={setUp}
                        statusType={statusType}
                    />
                    :
                    <Authentication
                        handleClose={toggleDrawer('right', false)}
                        handleOpen={toggleDrawer('right', true)}
                        setUp={setUp}
                        statusType={statusType}
                    />
                }
            </div>
        </div>
    );

    useEffect(() => {
        if (success) {
            setState({right: false});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success?.type));
        }
    }, [success]);


    return (
        <Fragment key={'right'}>
            <SettingsButton text={'Settings'} handleClick={toggleDrawer('right', true)}/>
            <SwipeableDrawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}>
                {list()}
            </SwipeableDrawer>
        </Fragment>
    );
};
