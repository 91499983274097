import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {CloseButton, MinLoader} from 'components';
import {FindLoad, FindSuccess} from 'hooks';
import {SavePage, useModal} from 'utils';
import {httpRequestsOnSuccessActions} from 'store';

export const CustomDeleteModal = ({actionType, handleDelete, callBack, title, description, deleteText}) => {
    const {close} = useModal();
    const dispatch = useDispatch();
    const success = FindSuccess(actionType);
    const load = FindLoad(actionType);
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (success) {
            callBack && callBack()
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
            const filteredList = {
                ...info,
            };
            delete filteredList.page;
            SavePage(history, info, {...filteredList});
        }
    }, [success]);

    return (
        <div className='delete-modal-fragment'>
            <div className='modal-header'>
                <CloseButton handleClose={close}/>
            </div>
            <div className='modal-body'>
                <p style={{textAlign: 'center'}} className='add-modal-title'>
                    {title || (
                        <>
                            Want to Delete <br/>
                            Product?
                        </>
                    )}
                </p>

                {description &&
                    <p className='add-modal-description'>
                        {description}
                    </p>
                }
                <div className='delete-modal-buttons'>
                    <button onClick={handleDelete} className='delete-button'>
                        {load ? <MinLoader color='white'/> : deleteText ? deleteText : 'Delete'}
                    </button>
                    <button className='cancel-button' onClick={close}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};
