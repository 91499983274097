import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";
import {Controller, useForm} from 'react-hook-form';
import moment from 'moment';
import {ebayActions, httpRequestsOnErrorsActions} from 'store';
import {AddModalButton, ErrMessage} from 'components';
import {FindError} from 'hooks';
import {Box, TextField} from '@mui/material';
import {AUTHENTICATION_TEXT_FIELD_STYLES_SMALL} from './constants';
import {useModal} from "utils";
import {CustomDeleteModal} from "./customDeleteModal";

export const SettingsEbay = ({handleClose,handleOpen, statusType, setUp}) => {
    /**
     * Settings
     */

    const dispatch = useDispatch();
    const backError = FindError(statusType);
    const [checkedSwitch, setCheckedSwitch] = useState(false);
    const {open} = useModal();
    const {
        handleSubmit,
        control,
        register,
        reset,
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            clientId: setUp?.clientId || '',
            clientSecret: setUp?.clientSecret || '',
            erplyWarehouse: setUp?.erplyWarehouse || '',
            erplyCustomer: setUp?.erplyCustomer || '',
        },
    });
    const history = useHistory();
    const info = history?.location?.state;
    const token = localStorage.getItem('access-token');


    const navigateTab = () => {
        const params = {
            ...info,
        };

        history.push('/ebay', {
            state: {
                ...params,
                tab: 0,
            },
        });
    };

    useEffect(() => {
        if (history?.location?.pathname === '/ebay/settings') {
            navigateTab();
            handleOpen()
        }
    }, [info]);

    useEffect(() => {
        if (setUp?.cronEnabled) {
            setCheckedSwitch(setUp?.cronEnabled);
        }
        if (setUp) {
            reset({
                clientId: setUp?.clientId || '',
                clientSecret: setUp?.clientSecret || '',
                erplyWarehouse: setUp?.erplyWarehouse || '',
                erplyCustomer: setUp?.erplyCustomer || '',
            });

        }
    }, [setUp]);

    const onSubmit = (data) => {
        if (setUp) {
            const info = {
                ...data,
            };

            if (data?.lastOrderDate) {
                const newDate = moment(info?.lastOrderDate).format('YYYY-MM-DDT00:00:01');
                info.data = new Date(newDate);
            } else {
                delete info.lastOrderDate;
            }
            info.cronEnabled = checkedSwitch;
            dispatch(ebayActions.createEbaySetup(info));
        } else {
            dispatch(ebayActions.createEbaySetup(data));
        }
    };

    const handleKeyDown = () => {
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(statusType));
        }
    };


    const connecting = () => {
        if (setUp?.isConnected) {
            open(
                <CustomDeleteModal
                    title='Are you sure you want to disconnect.'
                    description='This will unlink your eBay account and stop syncing data.'
                    deleteText='Disconnect'
                    handleDelete={() => dispatch(ebayActions.disconnectEbaySettings())}
                    actionType={'DISCONNECT_EBAY_SETTINGS'}
                />,
            )
        } else {
            window.open(`${process.env.REACT_APP_API_BASE}/ebay/setup/login?token=${token}`, '_parent');
        }
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)} className="credentials-form">
            <div>
                <Controller
                    name={'clientId'}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <TextField
                            {...register('clientId', {require: true})}
                            required={true}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            value={value}
                            label={'Client ID'}
                            size="medium"
                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                        />
                    )}
                />

                <Controller
                    name={'clientSecret'}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <TextField
                            {...register('clientSecret', {require: true})}
                            required={true}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            value={value}
                            label={'Client Secret'}
                            size="medium"
                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                        />
                    )}
                />

                <Controller
                    name={'erplyWarehouse'}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <TextField
                            {...register('erplyWarehouse', {require: false})}
                            required={true}
                            disabled={setUp?.isConnected}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            value={value}
                            label={'Erply Warehouse'}
                            size="medium"
                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                        />
                    )}
                />

                <Controller
                    name={'erplyCustomer'}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <TextField
                            {...register('erplyCustomer', {require: false})}
                            required={true}
                            disabled={setUp?.isConnected}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            value={value}
                            label={'Erply Customer'}
                            size="medium"
                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                        />
                    )}
                />

                <Box>{backError?.error && <ErrMessage text={backError.error}/>}</Box>

                {setUp &&
                    <div className='ebay-connected-wrapper'>
                        <p className='ebay-connected-text'>
                            eBay Connection
                        </p>

                        <button
                            className={`ebay-connect-button ${setUp?.status ? 'disconnect' : 'connect'}`}
                            type='button'
                            onClick={() => connecting()}
                        >
                            {setUp?.isConnected ? 'Disconnect' : 'Connect'}
                        </button>
                    </div>
                }
            </div>

            <div className="list-buttons-wrapper">
                <button className="chancel-button" onClick={handleClose} type={'button'}>
                    Cancel
                </button>
                <AddModalButton
                    text="Save"
                    type="submit"
                    load={setUp?.cronEnabled ? 'POST_EBAY_SETUP' : 'POST_EBAY_SETUP'}
                />
            </div>
        </form>
    );
};
