import {combineReducers} from 'redux';
import {authReducer} from '../auth';
import {httpRequestsOnLoadReducer} from '../http_requests_on_load';
import {httpRequestsOnSuccessReducer} from '../http_requests_on_success';
import {httpRequestsOnErrorsReducer} from '../http_requests_on_errors';
import {usersReducer} from '../users';
import {erplyReducer} from '../erply';
import {amazonReducer} from '../amazon';
import {ebayReducer} from '../ebay';
import {walmartReducer} from '../walmart';
import {carecraftReducer} from '../carecraft';
import {shopifyReducer} from "../shopify";
import {logsReducer} from "../logs";

export const appReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    erply: erplyReducer,
    amazon: amazonReducer,
    ebay: ebayReducer,
    shopify: shopifyReducer,
    walmart: walmartReducer,
    carecraft: carecraftReducer,
    logs: logsReducer,

    httpOnLoad: httpRequestsOnLoadReducer,
    httpOnSuccess: httpRequestsOnSuccessReducer,
    httpOnError: httpRequestsOnErrorsReducer,
});
