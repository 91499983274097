import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {CloseButton, SettingsButton, AddModalButton} from 'components';
import {FindError, FindSuccess} from 'hooks';
import {Controller, useForm} from 'react-hook-form';
import {TextField} from '@mui/material';
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, amazonActions, carecraftActions} from 'store';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AUTHENTICATION_TEXT_FIELD_STYLES_SMALL} from 'fragments/modals/constants';

export const LeftCredentials = ({statusType, setUp}) => {
    const dispatch = useDispatch();
    const backError = FindError(statusType);
    const success = FindSuccess(statusType);
    const [state, setState] = useState({right: false});

    const {
        handleSubmit,
        control,
        register,
        reset,
        formState: {errors},
    } = useForm({
        // mode: 'onBlur',
        defaultValues: {
            companyId: setUp?.companyId,
            apiKey: setUp?.apiKey,
        },
    });

    useEffect(() => {
        if (setUp) {
            reset({
                companyId: setUp?.companyId,
                apiKey: setUp?.apiKey,
            });
        }
    }, [setUp]);

    useEffect(() => {
        if (success) {
            setState({right: false});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success?.type));
        }
    }, [success]);

    const onSubmit = (data) => {
        dispatch(carecraftActions.createCareCraftSetup(data));
    };

    const handleKeyDown = () => {
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(statusType));
        }
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(statusType));
        }
    };

    const list = () => (
        <div className='list-wrapper'>
            <div className='list-wrapper-head'>
                <CloseButton handleClose={toggleDrawer('right', false)}/>
            </div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div className='list-wrapper-body'>
                    <p className='list-title'>Authentication Credentials</p>
                    <div style={{marginTop: '42px'}}>
                        <form onSubmit={handleSubmit(onSubmit)} className='credentials-form'>
                            <div>
                                <Controller
                                    name={'companyId'}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            {...register('companyId', {require: true})}
                                            InputLabelProps={{shrink: value ? true : ''}}
                                            required={true}
                                            onChange={onChange}
                                            onKeyDown={handleKeyDown}
                                            value={value}
                                            label={'Client ID'}
                                            size='medium'
                                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />

                                <Controller
                                    name={'apiKey'}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <TextField
                                            {...register('apiKey', {require: true})}
                                            InputLabelProps={{shrink: value ? true : ''}}
                                            required={true}
                                            onChange={onChange}
                                            onKeyDown={handleKeyDown}
                                            value={value}
                                            label={'API Key'}
                                            size='medium'
                                            sx={AUTHENTICATION_TEXT_FIELD_STYLES_SMALL}
                                        />
                                    )}
                                />

                                <div className='list-buttons-wrapper'>
                                    <button className='chancel-button' onClick={toggleDrawer('right', false)}
                                            type={'button'}>
                                        Cancel
                                    </button>

                                    <AddModalButton
                                        text='Save'
                                        type='submit'
                                        load={statusType}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment key={'right'}>
            <SettingsButton text={'Settings'} handleClick={toggleDrawer('right', true)}/>
            <SwipeableDrawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}>
                {list()}
            </SwipeableDrawer>
        </Fragment>
    );
};
