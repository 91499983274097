import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useDebounce} from 'use-debounce';
import {AddInviteButton, FullTable, InputMain} from 'components';
import {LeftDrawer} from 'fragments';
import {amazonActions, logsActions} from 'store';
import {Tab, Box, Tabs} from '@mui/material';
import {AddProductModal, amazonHead, buttonsStyle, amazonBody} from './fragments';
import {SavePage, useModal} from 'utils';
import {AmazonMap} from './fragments/amazonMap';
import {httpRequestsOnLoadActions} from '../../store/http_requests_on_load';
import {
    amazonErrorsBody, amazonErrorsHead,
    amazonInventoryTransferBody,
    amazonInventoryTransferHead,
    amazonOrderBody,
    amazonOrderHead,
} from './fragments/constants';
import {ProductMapModal} from './fragments/productMapModal';
import {Images} from 'assets/images';

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Amazon = ({}) => {
    const {
        amazonProducts,
        amazonOrders,
        amazonTransvers,
        setUp,
        logs,
    } = useSelector((state) => ({
        amazonProducts: state.amazon.amazonProducts,
        amazonOrders: state.amazon.amazonOrders,
        amazonTransvers: state.amazon.amazonTransvers,
        setUp: state.amazon.setUp,
        logs: state.logs.allLogs,

    }));
    const classes = buttonsStyle();
    const dispatch = useDispatch();
    const {open} = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const [search, setSearch] = useState(null);
    const [searchValues] = useDebounce(search, 500);
    const [showSelected, setShowSelected] = useState('');
    const {control, watch, setValue} = useForm({
        defaultValues: {
            search: ""
        }
    });
    const searchErply = watch('search');
    const [erplyCode] = useDebounce(searchErply, 500);
    const checkTab = info?.tab === 0 ? true : !info?.tab;


    useEffect(() => {
        dispatch(amazonActions.getAmazonSetup());
    }, []);

    useEffect(() => {
        dispatch(amazonActions.getAmazonType());
    }, []);


    useEffect(() => {
        handleGet(searchValues ? 'noLoad' : 'loading');
        if (searchValues) {
            dispatch(httpRequestsOnLoadActions.appendLoading(searchValues));
        }
    }, [searchValues, erplyCode, info]);


    const handleChange = (event, newValue) => {
        SavePage(history, info, {tab: newValue});
        setShowSelected('');
        setValue('search', '');
    };

    const renderParams = () => {
        const filteredInfo = searchValues ? {
            ...info,
            ...searchValues,
        } : {
            ...info,
        };

        if (erplyCode) filteredInfo.erplyCode = erplyCode

        delete filteredInfo.page;
        delete filteredInfo.tab;

        return filteredInfo;
    };


    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad';
        const filteredInfo = renderParams();

        if (!info?.tab || info?.tab === 0) {
            dispatch(amazonActions.getAmazonOrders(filteredInfo));
        } else if (info?.tab === 2) {
            dispatch(amazonActions.getAmazonTransvers(filteredInfo));
        } else if (info?.tab === 3) {
            dispatch(logsActions.getLogsSku({...filteredInfo, store: 'AMAZON'}));
        } else {
            dispatch(amazonActions.getAmazon(filteredInfo, loader, searchValues));
        }
    };


    const handleSearchReq = (value, name) => {
        if (info?.page !== 1) {
            SavePage(history);
        }

        setSearch((prev) => {
            const newSearchValues = prev ? {...prev} : {};
            (!value && newSearchValues[name]) ? delete newSearchValues[name] : newSearchValues[name] = value

            if (Object.keys(newSearchValues).length > 0) {
                return (
                    {
                        ...newSearchValues
                    }
                )
            } else {
                return null
            }

        });
    }

    const handleClickToButton = (item) => {
        const filteredInfo = renderParams();
        if (info?.tab === 3) {
            dispatch(logsActions.resolveLogsSku({...filteredInfo, store: 'AMAZON'}, item.id));
        }
    };


    const renderTable = () => {
        switch (info?.tab) {
            case 0:
                return {
                    head: amazonOrderHead,
                    body: amazonOrderBody,
                    actionType: 'GET_AMAZON_ORDERS',
                    list: amazonOrders?.orders,
                    count: amazonOrders?.count,
                };

            case 1:
                return {
                    head: amazonHead,
                    body: amazonBody,
                    actionType: 'GET_AMAZON',
                    list: amazonProducts?.mappings,
                    count: amazonProducts?.count,
                };

            case 2:
                return {
                    head: amazonInventoryTransferHead,
                    body: amazonInventoryTransferBody,
                    actionType: 'GET_AMAZON_TRANSVERS',
                    list: amazonTransvers?.inventoryTransfer,
                    count: amazonTransvers?.count,
                };

            case 3:
                return {
                    head: amazonErrorsHead,
                    body: amazonErrorsBody(handleClickToButton),
                    actionType: 'GET_LOGS_SKU',
                    list: logs?.logs,
                    count: logs?.count,
                    noText: 'Error',
                };

            default:
                return {
                    head: amazonOrderHead,
                    body: amazonOrderBody,
                    actionType: 'GET_AMAZON_ORDERS',
                    list: amazonOrders?.orders,
                    count: amazonOrders?.count,
                };
        }
    };

    const checkType =
        info?.tab ? info?.tab === 0 ? true :
                info?.tab === 1 ? true :
                    info?.tab === 2 ? true :
                        ''
            : true;


    const renderTableValues = renderTable();

    const handleOpenProductMap = (item) => {
        setShowSelected(item);
        dispatch(amazonActions.orderProductMap(item?.amazonSKU));
    };

    return (
        <div>
            <div className="title-buttons-wrapper">
                <div className="">
                    <Box className="tabBox">
                        <Box>
                            <Tabs className={classes.buttonsTab} value={info?.tab ? info?.tab : 0}
                                  onChange={handleChange}
                                  aria-label="basic tabs example">
                                <Tab className="tab" label="Orders" {...tabProps(0)} />
                                <Tab className="tab" label="Product Map" {...tabProps(1)} />
                                <Tab className="tab" label="Inventory Transfer" {...tabProps(2)} />
                                <Tab className="tab" label="Errors" {...tabProps(3)} />
                            </Tabs>
                        </Box>
                    </Box>
                </div>

                {(info?.tab === 1 || info?.tab === 0 || !info?.tab) &&
                    <div className="buttons-wrapper">
                        {info?.tab === 1 &&
                            <AddInviteButton
                                text={'Add'}
                                handleClick={() => open(<AddProductModal/>)}
                            />
                        }
                        <LeftDrawer type={'amazon'} statusType={'CREATE_AMAZON'} setUp={setUp}
                                    actionType={'PATCH_AMAZON_SETUP'}/>
                    </div>
                }

            </div>

            {info?.tab === 1 &&
                <div style={{margin: '16px 0 0'}}>
                    <InputMain
                        className="search-erply-input"
                        placeholder="Search by Erply Code..."
                        name="search"
                        control={control}
                        showError={false}
                        type={'search'}
                        startAdornment={<Images.SearchNormalSvg/>}
                    />
                </div>
            }
            {checkType &&
                <div
                    style={{display: 'flex'}}
                >
                    <AmazonMap
                        renderParams={renderParams}
                        handleOpen={handleOpenProductMap}
                        head={renderTableValues?.head}
                        body={renderTableValues?.body}
                        loadingType={renderTableValues?.actionType ? renderTableValues?.actionType : 'GET_AMAZON_ORDERS'}
                        type={
                            info?.tab === 1 ? 'map' :
                                info?.tab === 2 ? 'transfer' :
                                    ''}
                        list={renderTableValues?.list}
                        listCount={renderTableValues?.count}
                        handleSearchReq={handleSearchReq}
                    />

                    {checkTab && showSelected &&
                        <ProductMapModal handleClose={() => setShowSelected(null)}/>
                    }
                </div>
            }

            {!checkType &&
                <FullTable
                    head={renderTableValues?.head}
                    body={renderTableValues?.body}
                    loadingType={renderTableValues?.actionType}
                    list={renderTableValues?.list}
                    listCount={renderTableValues?.count}
                    noText={renderTableValues?.noText}
                />
            }
        </div>
    );
}
