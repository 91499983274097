import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {
    Users,
    Amazon,
    Walmart,
    Ebay,
    Erplay,
    Carecraft,
    ShopifyStore,
    SingleShopifyStore,
    ShopifyErrors,
    ShopifyMapPage,
    ShopifyInventoryTransfers
} from 'pages';

export const Router = () => {
    return (
        <Switch>
            <Route path={'/users'} component={Users}/>
            <Route path={'/erply'} component={Erplay}/>
            <Route path={'/amazon'} component={Amazon}/>
            <Route path={'/ebay'} component={Ebay}/>
            <Route exact path={'/shopify/stores'} component={ShopifyStore}/>
            <Route path={'/shopify/stores/:id'} component={SingleShopifyStore}/>
            <Route path={'/shopify/product-map'} component={ShopifyMapPage}/>
            <Route path={'/shopify/inventory-transfers'} component={ShopifyInventoryTransfers}/>
            <Route path={'/shopify/errors'} component={ShopifyErrors}/>
            {/*<Route path={'/walmart'} component={Walmart} />*/}
            {/*<Route path={'/ebay'} component={Ebay} />*/}
            <Route path={'/carecraft'} component={Carecraft}/>
        </Switch>
    );
};
