import axios from 'axios';
import {disconnectEbaySettings} from "./ebay.action";

export const authService = {

    /** Create, Edit Ebay Product */

    createEbayService: (body) => axios.post(`/map`, { ...body, type: 'EBAY' }, { auth: true }),

    getEbayService: (params) => axios.get(`/map`, { auth: true, params: { ...params, type: 'EBAY' } }),

    editEbayMap: (body, id) => axios.patch(`/map/${id}`, { ...body, type: 'EBAY' }, { auth: true }),

    deleteEbayMap: (id) => axios.delete(`/map/${id}`, { auth: true, params: { type: 'EBAY' } }),

    /** End */

    /**  Creaet , Edit, Delete and Get Ebay Product  */

    addEbayType: (body) => axios.post(`/product-type`, body, { auth: true }),

    getEbayType: (params) => axios.get(`/product-type`, { auth: true, params: { ...params } }),

    editEbayType: (body, id) => axios.patch(`/product-type/${id}`, body, { auth: true }),

    deleteEbayType: (id) => axios.delete(`/product-type/${id}`, { auth: true }),

    /** End */

    /**  Ebay Setup  */

    getEbaySetup: () => axios.get(`/ebay/setup`, { auth: true }),

    editEbaySetup: (body) => axios.patch(`/ebay/setup`, body, { auth: true }),

    creatEbaySetup: (body) => axios.post(`/ebay/setup`, body, { auth: true }),

    disconnectEbaySettingsSetupService: () => axios.get(`/ebay/setup/disconnect`,{ auth: true }),

    /**  Ebay Orders  */

    getEbayOrdersService: (params) => axios.get(`/ebay/orders`, { auth: true, params: params }),

    getEbayTransversService: (params) => axios.get(`/transfer`, {
        auth: true,
        params: { ...params, type: 'EBAY' },
    }),

    syncResyncService: (params, orderId) => axios.patch(`/core/ebay/orders/${orderId}`, null, {
        auth: true,
        params: params,
    }),

    orderProductMapService: (id) => axios.get(`/map/sku/${id}`, { auth: true, params: { type: 'EBAY' } }),

    /** End */

};
