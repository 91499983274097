
/** Create Ebay */
export const CREATE_EBAY = 'CREATE_EBAY';

/** Add, Edit Ebay product */
export const ADD_EBAY_PRODUCT = 'ADD_EBAY_PRODUCT'

export const EDIT_EBAY_PRODUCT = 'EDIT_EBAY_PRODUCT'

/** Get Ebay */
export const GET_EBAY = 'GET_EBAY';
export const GET_EBAY_SUCCESS = 'GET_EBAY_SUCCESS';

/** Delete Ebay Product */
export const DELETE_EBAY_PRODUCT = 'DELETE_EBAY_PRODUCT';
export const DELETE_EBAY_PRODUCT_SUCCESS = 'DELETE_EBAY_PRODUCT_SUCCESS';

/** EBAY Product Type */
export const GET_EBAY_TYPE = 'GET_EBAY_TYPE';
export const GET_EBAY_TYPE_SUCCESS = 'GET_EBAY_TYPE_SUCCESS';

export const EDIT_EBAY_TYPE = 'EDIT_EBAY_TYPE';
export const EDIT_EBAY_TYPE_SUCCESS = 'EDIT_EBAY_TYPE_SUCCESS';

export const ADD_EBAY_TYPE = 'ADD_EBAY_TYPE';
export const ADD_EBAY_TYPE_SUCCESS = 'ADD_EBAY_TYPE_SUCCESS';

export const DELETE_EBAY_TYPE = 'DELETE_EBAY_TYPE';
export const DELETE_EBAY_TYPE_SUCCESS = 'DELETE_EBAY_TYPE_SUCCESS';

/** Ebay Setup Endpoints */

export const GET_EBAY_SETUP = 'GET_EBAY_SETUP';
export const GET_EBAY_SETUP_SUCCESS = 'GET_EBAY_SETUP_SUCCESS';

export const PATCH_EBAY_SETUP = 'PATCH_EBAY_SETUP';
export const PATCH_EBAY_SETUP_SUCCESS = 'PATCH_EBAY_SETUP_SUCCESS';

export const POST_EBAY_SETUP = 'POST_EBAY_SETUP';
export const POST_EBAY_SETUP_SUCCESS = 'POST_EBAY_SETUP_SUCCESS';

export const DISCONNECT_EBAY_SETTINGS = "DISCONNECT_EBAY_SETTINGS"

/** Ebay Orders */

export const GET_EBAY_ORDERS = 'GET_EBAY_ORDERS'
export const GET_EBAY_ORDERS_SUCCESS = 'GET_EBAY_ORDERS_SUCCESS'

export const GET_EBAY_TRANSVERS = 'GET_EBAY_TRANSVERS'
export const GET_EBAY_TRANSVERS_SUCCESS = 'GET_EBAY_TRANSVERS_SUCCESS'
export const SYNC_RESYNC = 'SYNC_RESYNC'
export const GET_ORDER_PRODUCT_MAP = 'GET_ORDER_PRODUCT_MAP'
export const GET_ORDER_PRODUCT_MAP_SUCCESS = 'GET_ORDER_PRODUCT_MAP_SUCCESS'

