import {
    ADD_EBAY_TYPE_SUCCESS,
    DELETE_EBAY_TYPE_SUCCESS,
    EDIT_EBAY_TYPE_SUCCESS,
    GET_EBAY_SETUP,
    GET_EBAY_SUCCESS,
    GET_EBAY_TYPE_SUCCESS,
    POST_EBAY_SETUP,
    PATCH_EBAY_SETUP,
    GET_EBAY_SETUP_SUCCESS,
    DELETE_EBAY_PRODUCT_SUCCESS,
    EDIT_EBAY_PRODUCT,
    GET_EBAY_ORDERS_SUCCESS,
    GET_EBAY_TRANSVERS_SUCCESS,
    GET_ORDER_PRODUCT_MAP_SUCCESS,
    GET_EBAY_PRODUCT_SETTINGS_SUCCESS,
} from './ebay.types';

const initialState = {
    ebayProducts: null,
    deleteAbayProduct: null,
    editProduct: null,
    setUp: null,
    ebayType: null,
    addEbayType: null,
    editEbayType: null,
    deleteEbayId: null,
    getEbaySetup: null,
    addEbaySetup: null,
    editEbaySetup: null,
    ebayOrders: null,
    ebayTransvers: null,
    orderProductMap: null,
};

export const ebayReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Get Ebay Products */

        case GET_EBAY_SUCCESS:
            return {
                ...state,
                ebayProducts: action.payload,
            };

        case GET_EBAY_SETUP_SUCCESS:
            return {
                ...state,
                setUp: { ...action.payload },
            };

        case DELETE_EBAY_PRODUCT_SUCCESS:
            return {
                ...state,
                deleteEbayProduct: action.payload,

            };
        case EDIT_EBAY_PRODUCT: {
            return {
                ...state,
                editProduct: action.payload,
            };
        }

        /** Ebay Type */

        case GET_EBAY_TYPE_SUCCESS:
            return {
                ...state,
                ebayType: action.payload,
            };

        case ADD_EBAY_TYPE_SUCCESS:
            return {
                ...state,
                addEbayType: action.payload,
            };

        case EDIT_EBAY_TYPE_SUCCESS:
            return {
                ...state,
                editEbayType: action.payload,
            };

        case DELETE_EBAY_TYPE_SUCCESS:
            return {
                ...state,
                deleteEbayId: action.payload,
            };

        /** Ebay Setup  */

        case GET_EBAY_SETUP:
            return {
                ...state,
                getEbaySetup: action.payload,
            };

        case POST_EBAY_SETUP:
            return {
                ...state,
                addEbaySetup: action.payload,
            };

        case PATCH_EBAY_SETUP:
            return {
                ...state,
                editEbaySetup: action.payload,
            };

        /** ebay Orders  */

        case GET_EBAY_ORDERS_SUCCESS:
            return {
                ...state,
                ebayOrders: action.payload,
            };

        case GET_EBAY_TRANSVERS_SUCCESS:
            return {
                ...state,
                ebayTransvers: action.payload,
            };

        case GET_ORDER_PRODUCT_MAP_SUCCESS:
            return {
                ...state,
                orderProductMap: action.payload,
            };


        default:
            return state;
    }
};
